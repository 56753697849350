(function () {
	const second = 1000,
		minute = second * 60,
		hour = minute * 60,
		day = hour * 24;

	//I'm adding this section so I don't have to keep updating this pen every year :-)
	//remove this if you don't need it
	let today = new Date(),
		dd = String(today.getDate()).padStart(2, '0'),
		mm = String(today.getMonth() + 1).padStart(2, '0'),
		yyyy = today.getFullYear();

	let dateEl = $('#countdownClock').attr('data-date');
	let date = new Date(parseInt(dateEl) * 1000);

	const countDown = new Date(date).getTime(),
		x = setInterval(function () {
			const now = new Date().getTime(),
				distance = countDown - now;

			(document.getElementById('days').innerText = Math.floor(
				Math.max(distance, 0) / day,
			)),
				(document.getElementById('hours').innerText = Math.floor(
					(Math.max(distance, 0) % day) / hour,
				)),
				(document.getElementById('minutes').innerText = Math.floor(
					(Math.max(distance, 0) % hour) / minute,
				)),
				(document.getElementById('seconds').innerText = Math.floor(
					(Math.max(distance, 0) % minute) / second,
				));

			//do something later when date is reached
			if (distance < 0) {
				clearInterval(x);
			}
			//seconds
		}, 0);
})();
